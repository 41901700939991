import React from 'react';

import * as styles from './grid.module.scss';

export function Grid({
  align = null,
  valign = null,
  gutters = null,
  children,
  className = null,
}) {
  const classNames = [styles.grid];
  align && classNames.push(styles[`gridH${align}`]);
  valign && classNames.push(styles[`gridV${valign}`]);
  gutters && classNames.push(styles[`gridGutters${gutters}`]);
  className && classNames.push(className);

  return <div className={classNames.join(' ')}>{children}</div>;
}

export function Col({
  width = null,
  lgWidth,
  mdWidth,
  smWidth,
  children,
  className = null,
}) {
  const classNames = [styles.col];
  width && classNames.push(styles[`colW${width}`]);
  lgWidth && classNames.push(styles[`colLgW${lgWidth}`]);
  mdWidth && classNames.push(styles[`colMdW${mdWidth}`]);
  smWidth && classNames.push(styles[`colSmW${smWidth}`]);
  className && classNames.push(className);

  return <div className={classNames.join(' ')}>{children}</div>;
}
