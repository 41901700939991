import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout/layout';
import Container from '../../components/container/container';
import { Grid, Col } from '../../components/grid/grid';

import * as styles from './about-us.module.scss';

export default function AboutUsPage() {
  return (
    <Layout title="About Us">
      <div className={styles.content}>
        <Container className={styles.content__inner}>
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consuat Duis aute irure dolor in
            reprehenderit in voluptate velit.
          </p>
          <p>
            Jabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur.
          </p>
          <StaticImage
            src="../../images/about-page-img-1.png"
            alt="A stylish home interior"
            placeholder="blurred"
            layout="fullWidth"
            className={styles.content__image}
          />
          <h1 className={styles.headline}>
            Building spectacular homes with luxury finish
          </h1>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <p>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </p>
        </Container>
      </div>
      <div className={styles.imageGrid}>
        <StaticImage
          src="../../images/about-page-img-2.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={styles.imageGrid__image}
        />
        <StaticImage
          src="../../images/about-page-img-3.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={styles.imageGrid__image}
        />
        <StaticImage
          src="../../images/about-page-img-4.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={styles.imageGrid__image}
        />
      </div>
    </Layout>
  );
}
